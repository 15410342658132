<template>
  <div id="home" v-if="isReady">
    <div class="top-bar">
      <div class="topbar-inner">
        <img src="@/assets/logo.png" alt="logo" class="logo">
        <div class="menu-li">
          <div class="ml-item">
            <a :href="iniData.zuolist.nv1" target="_blank"><i class="icon home"></i>首页</a>
          </div>
          <div class="ml-item" @click="showSearch = true">
            <a href="javascript:;"><i class="icon search"></i>中奖查询</a>
          </div>
          <div class="ml-item">
            <a :href="iniData.zuolist.nv2" target="_blank"><i class="icon gift"></i>优惠中心</a>
            </div>
          <div class="ml-item">
            <a :href="iniData.zuolist.nv3" target="_blank"><i class="icon app"></i>APP下载</a>
          </div>
          <div class="ml-item">
            <a :href="iniData.zuolist.nv4" target="_blank"><i class="icon serve"></i>联系客服</a>
          </div>
        </div>
      </div>
    </div>
    <div class="app-page">
      <div class="app-container">
        <div class="lucky-grid">
          <img :src="require('../assets/pc/blocks-pc.png')" class="lucky-img" />
          <LuckyGrid
            ref="LuckDraw"
            width="1200px"
            height="695px"
            rows="5"
            cols="6"
            :blocks="blocks"
            :prizes="prizes"
            :buttons="buttons"
            :default-config="defaultConfig"
            :default-style="defaultStyle"
            :active-style="activeStyle"
            @start="startCallBack"
            @end="endCallBack"
          />
          <div class="lucky-btn" @click="startCallBack" :class="animation ? 'animation' : ''">
            <div class="lcy-btn-inner">开始抽奖</div>
          </div>
        </div>
        <div class="tm-content">
          <div class="time-down" v-if="timeDownShow">
            <count-down tipText="距离活动开始倒计时" tipTextEnd="距离活动结束倒计时" :start-time="defaultTime.start_time" :end-time="defaultTime.end_time" @start_callback="countDownS_cb()" @end_callback="countDownE_cb()"></count-down>
          </div>
          <div class="mq-cxt">
            <div class="mq-title-bar">
              <h3>中奖名单</h3>
              <p>The winners</p>
            </div>
            <div class="mq-main">
              <vueSeamlessScroll :data="listData" :class-option="defaultOption" class="seamless-wrap">
                <div class="jp-list">
                  <p v-for="(item, index) in listData" :key="index">{{item.title}}</p>
                </div>
              </vueSeamlessScroll>
            </div>
          </div>
        </div>
        <div class="mml-container">
          <div class="mmlc-content">
            <div class="csib-details">
              <div class="event-detail">
                <div class="content-header">
                  <h3>活动详情</h3>
                  <p>EVENT DETAILS</p>
                </div>
                <div class="decorate-line"></div>
                <div class="mmlc-citem" v-html="iniData.pcactcontent">
                </div>
              </div>
              <div class="beaut-tbl" v-html="iniData.pcxzcontent"></div>
            </div>
            <div class="csob-details">
              <div class="content-header">
                <h3>活动说明</h3>
                <p>EVENT DETAILS</p>
              </div>
              <div class="decorate-line"></div>
              <div class="mmlc-citem" v-html="iniData.pcyhcontent"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="copyright">
        <p>{{iniData.cright}}</p>
      </div>
    </div>
    <van-popup
      v-model="userPopShow"
      closeable
      :style="{ width: '588px', height: '390px' }"
      @open="username = ''"
      class="popup-user-block"
    >
      <div class="account-container">
        <img src="@/assets/logo.png" />
        <h3 class="title-bar">登录会员账号</h3>
        <div class="field__value-screen">
          <input type="text" placeholder="账号" v-model="username" class="field__control-screen" ref="ipt" @focus="$event.target.select()" />
          <button class="button--info-screen" @click="confirm">立即登录</button>
        </div>
      </div>
    </van-popup>
    <van-popup
      v-model="showSearch"
      closeable
      :style="{ width: '780px', height: '540px' }"
      @close="closeSearchPopup"
      class="popup-search-block">
      <div class="block--title">输入会员账号查询</div>
      <div class="mcain-content">
        <div class="search-content">
          <div class="s-c-i">
            <span class="x-t">会员账号：</span>
            <input type="text" placeholder="账号" v-model="queryname" class="field__control" />
          </div>
          <div class="s-c-b">
            <button class="button--info" @click="queryResl">查询</button>
          </div>
        </div>
        <div class="result-content">
          <ul class="rc-head">
            <li>奖品名称</li>
            <li>领取时间</li>
            <li>是否派彩</li>
          </ul>
          <div class="rc-main" v-if="resl.length">
            <ul class="rc-body" v-for="(item, index) in resl" :key="index">
              <li>{{item.item_name}}</li>
              <li>{{item.used_time}}</li>
              <li>{{item.distribute_status === 1 ? '是' : '否'}}</li>
            </ul>
          </div>
          <div v-else style="font-size: 16px;color: #fff;text-align: center;height: 48px;line-height: 48px;">暂无记录！</div>
        </div>
      </div>
    </van-popup>
    <van-popup
      v-model="showCoupons"
      closeable
      :style="{ width: '434px', height: '425px' }"
      @close="closeCouponsPopup"
      class="popup-coupons-block">
      <div class="coupons-word">{{couponsWord}}</div>
    </van-popup>
  </div>
</template>

<script>
import CountDown from 'vue2-countdown'
import vueSeamlessScroll from 'vue-seamless-scroll'

export default {
  name: 'App',
  components: {
    CountDown,
    vueSeamlessScroll
  },
  data () {
    return {
      listData: [],
      defaultOption: {
        step: 0.2,
        limitMoveNum: 3,
        hoverStop: false,
        direction: 0,
        openWatch: true
      },
      showSearch: false,
      resl: [],
      defaultTime: {
        c_time: '',
        start_time: '',
        end_time: ''
      },
      iniData: {
        news: '',
        pcactcontent: '',
        pcxzcontent: '',
        pcyhcontent: '',
        mactcontent: '',
        cright:'',
        zuolist: {
          nv1: '#',
          nv2: '#',
          nv3: '#',
          nv4: '#',
        }
      },
      username: '',
      queryname: '',
      timeDownShow: false,
      hasTime: false,
      baseUrl: 'https://zzl.ee',
      isReady: false,
      luckyNum: 0,
      blocks: [
        { padding: '30 35', background: 'rgba(0, 0, 0, 0)', borderRadius: 10 },
      ],
      prizes: [],
      defaultConfig: {
        gutter: 12,
      },
      defaultStyle: {
        fontColor: '#994444',
        fontSize: '14px',
        lineHeight: '18px',
        wordWrap: false
      },
      activeStyle: {
        fontSize: '14px',
        fontColor: '#994444',
        fontWeight: 'weight',
        background: 'rgba(0, 0, 0, 0)'
      },
      userPopShow: false,
      showLuckNum: false,
      animation: false,
      showCoupons: false,
      couponsWord: ''
    }
  },
  beforeRouteEnter (to, from, next) {
    window.document.body.style.cssText = 'background: #f84747';
    window.document.documentElement.style.cssText = 'height: 100%;font-size: 14px;'
    next()
  },
  beforeRouteLeave (to, from, next) {
    window.document.body.style.cssText = ''
    window.document.documentElement.style.cssText = ''
    next()
  },
  created: function (){
    this.initDefaultData();
  },
  computed: {
    buttons () {
      return [{
        x: 1,
        y: 1,
        col: 4,
        row: 3,
        background: 'rgba(0, 0, 0, 0)',
        fonts: [
          { text: `${this.showLuckNum ? `剩余次数：${this.luckyNum} 次` : ''}`, fontColor: '#fff', top: '95%', fontSize: '14px' },
        ],
        imgs: [
          // { src: require('../assets/buttons-pc.png'), height: '100%' }
        ]
      }]
    }
  },
  methods: {
    getPrizeList () {
      var self = this
      return new Promise(resolve => {
        this.axios({
          method: 'post',
          url: self.baseUrl + '/index/Index/getitems'
        }).then(res => {
          resolve(res.data.data.list)
        })
      })
    },
    startCallBack () {
      var self = this
      if (!this.hasTime) {
        this.$toast.fail('活动尚未开始')
        return
      }
      if (!this.luckyNum) {
        if (this.username) {
          return this.$dialog.confirm({
            message: '抽奖机会已被用光，是否为其他账号抽奖！',
            confirmButtonText: '是',
            cancelButtonText: '否'
          }).then(() => {
            this.userPopShow = true
            this.username = '';
          }).catch(() => {
            
          })
        } else {
          return this.userPopShow = true
        }
      }
      this.$toast.loading({
        duration: 0,
        message: '加载中...',
        forbidClick: true,
        loadingType: 'spinner'
      })
      this.animation = true
      this.axios({
        method: 'post',
        url: self.baseUrl + '/index/Index/getpacket',
        data: {
          username: self.username
        }
      }).then(res => {
        self.$toast.clear()
        var data = res.data
        if (data.code != 0) return this.$toast.fail(data.msg)
        this.$refs.LuckDraw.play()
        var giftId = data.data.itemid - 1
        self.$refs.LuckDraw.stop(giftId)
        self.luckyNum = data.data.times
      })
    },
    endCallBack (prize) {
      this.animation = false
      this.showCoupons = true
      if(prize.x == 1 && prize.y == 3){
        this.couponsWord = `${prize.fonts[0].text}`
        // this.$dialog.alert({
        //   message: `${prize.fonts[0].text}`
        // })
      }else{
        this.couponsWord = `恭喜你获得大奖: ${prize.fonts[0].text}`
        // this.$dialog.alert({
        //   message: `恭喜你获得大奖: ${prize.fonts[0].text}`
        // })
      }
    },
    closeCouponsPopup () {
      this.couponsWord = ''
    },
    initDefaultData () {
      var self = this;
      this.$toast.loading({
        duration: 0,
        message: '加载中...',
        forbidClick: true,
        loadingType: 'spinner',
      });
      Promise.all([
        self.getPrizeList(),
        self.initIndexData(),
        self.initTimeData(),
        self.getInfoList()
      ]).then(([prizeList, indexList, timeData, infoList]) => {
        self.$toast.clear();
        const data = []
        // prizeList = prizeList.concat(prizeList.slice(0, 2)) // 需要注释掉
        prizeList.forEach(item => {
          data.push({
            name: item.name,
            img: self.baseUrl + item.pic
          })
        })
        const prizes = []
        let axis = [[0, 0], [1, 0], [2, 0], [3, 0], [4, 0], [5, 0], [5, 1], [5, 2], [5, 3], [5, 4], [4, 4], [3, 4], [2, 4], [1, 4], [0, 4], [0, 3], [0, 2], [0, 1]] // 5 * 5 格子
        // let axis = [[0, 0], [1, 0], [2, 0], [3, 0], [3, 1], [3, 2], [3, 3], [2, 3], [1, 3], [0, 3], [0, 2], [0, 1]] // 4 * 4 格子
        // let axis = [[0, 0], [1, 0], [2, 0], [2, 1], [2, 2], [1, 2], [0, 2], [0, 1]] // 3 * 3 格子
        data.forEach((item, index) => {
          prizes.push({
            x: axis[index][0],
            y: axis[index][1],
            title: item.name,
            fonts: [{ text: item.name, top: 90 }],
            imgs: [
              {
                src: require('../assets/cell.png'),
                activeSrc: require(`../assets/active.png`),
                width: '100%',
                height: '100%',
              },
              {
                src: item.img,
                height: 80,
                top: 10
              }
            ]
          })
        })
        self.prizes = prizes

        self.iniData = indexList.data;
        self.defaultTime = timeData.data;
        self.timeDownShow = true;
        self.isReady = true;
        self.listData = infoList.data.rec;
        if(self.listData.length<3){
          self.listData = [];
        }
        //self.listData = self.listData.slice(0, -(self.listData.length%3))
      }).catch(err => {
        console.log(err)
        self.$toast.clear();
      })
    },
    initTimeData () {
      var self = this;
      return new Promise(resolve => {
        this.axios({
          method: 'post',
          url: self.baseUrl + '/index/Index/gettime'
        }).then(function (res){
          resolve(res.data)
        })
      })
    },
    initIndexData () {
      var self = this;
      return new Promise(resolve => {
        this.axios({
          method: 'post',
          url: self.baseUrl + '/index/Index/getindex'
        }).then(function (res){
          resolve(res.data)
        })
      })
    },
    getInfoList () {
      var self = this;
      return new Promise(resolve => {
        this.axios({
          method: 'post',
          url: self.baseUrl + '/index/Index/getcjrec'
        }).then(function (res){
          resolve(res.data)
        })
      })
    },
    confirm () {
      var self = this;
      if (!this.username) {
        this.$toast.fail('请输入账号')
        return
      }
      this.$toast.loading({
        duration: 0,
        message: '加载中...',
        forbidClick: true,
        loadingType: 'spinner',
      });
      this.axios({
        method: 'post',
        url: self.baseUrl + '/index/Index/checkuser',
        data: {
          username: self.username
        }
      }).then(function (res){
        self.$toast.clear();
        if (res.data.code == 0) {
          self.userPopShow = false
          self.showLuckNum = true
          self.luckyNum = res.data.data.times
        } else {
          self.username = '';
          self.$toast.fail(res.data.msg)
          self.$refs.ipt.focus()
        }
      }).catch(err => {
        console.log(err)
        self.$toast.clear();
      })
    },
    queryResl () {
      var self = this;
      if (!this.queryname) {
        this.$toast.fail('请输入账号')
        return
      }
      this.$toast.loading({
        duration: 0,
        message: '加载中...',
        forbidClick: true,
        loadingType: 'spinner',
      });
      this.axios({
        method: 'post',
        url: self.baseUrl + '/index/Index/query',
        data: {
          username: self.queryname
        }
      }).then(function (res){
        self.$toast.clear();
        if (res.data.code == 0) {
          self.resl = res.data.data.list
        }
      }).catch(err => {
        console.log(err)
        self.$toast.clear();
      })
    },
    countDownS_cb() {
      this.hasTime = true
    },
    countDownE_cb() {
      this.hasTime = false
    },
    closeSearchPopup () {
      this.queryname = ''
      this.resl = []
    }
  }
}
</script>

<style lang="stylus" scoped>
.popup-user-block
  background-color rgb(114, 8, 8)
  border-radius 10px
.csib-details
  display flex
  margin-top 50px
.csob-details
  margin-top 15px
  padding 0 15px
.event-detail
  width 560px
  padding 0 15px
.lucky-grid
  position relative
  margin-top 255px
  .lucky-img
    position absolute
    width 100%
    height 100%
    left 0
    top 0
.tm-content
  margin-top 100px
  background url('~@/assets/timedown.png') no-repeat
  .mq-cxt
    height 350px
    .mq-title-bar
      h3, p
        margin 0
      h3
        font-size 38px
        color #e2da0a
        font-weight normal
      p
        font-size 22px
        color #fff
    .mq-main
      width 1000px
      margin 35px auto 0
      height 180px
      overflow hidden
      .jp-list
        display flex
        flex-flow wrap
      p
        color #f0f0f5
        font-size 18px
        margin 0
        width 33%
        text-align left
      &:first-child
        border-left none
.time-down
  display flex
  justify-content center
  align-items center
  height 137px
  .custom-time
    display flex
    justify-content center
    align-items center
  >>> .title
    margin 0 30px 0 0
    background url('~@/assets/clock.png') no-repeat
    padding 0 0 0 60px
    height: 48px
  >>> .num
    color #f8a421
    font-weight normal
#home{
  text-align: center
}
.app-page{
  display: flex
  flex-direction: column
  background: url('~@/assets/pc/background.jpg') no-repeat 50% 0
}
.top-bar{
  height: 92px
}
.topbar-inner{
  width: 1200px
  display: flex
  justify-content: space-between
  align-items: center
  position: relative
  margin: 0 auto
        height: 96px
}
.top-bar img{
  margin-left: 36px
  max-height: 75px
}
.menu-li{
  display: flex
  justify-content: center
  align-items: center
}
.ml-item{
  height: 36px
  font-size: 16px
  cursor: pointer
  display flex
  a{
    display: flex
    align-items center
    width 100%
    color #fff
    padding-left 32px
  }
  .icon{
    display flex
    width 24px
    height 24px
    margin-right 12px
  }
  .home{
    background: url('~@/assets/home.png') no-repeat 50% 50%
    background-size auto 24px
  }
  .search{
    background: url('~@/assets/search.png') no-repeat 50% 50%
    background-size auto 24px
  }
  .gift{
    background: url('~@/assets/gift.png') no-repeat 50% 50%
    background-size auto 24px
  }
  .app{
    background: url('~@/assets/app.png') no-repeat 50% 50%
    background-size auto 24px
  }
  .serve{
    background: url('~@/assets/service.png') no-repeat 50% 50%
    background-size auto 24px
  }
}
.app-container{
  display: flex
  position: relative
  width: 1200px
  margin: 0 auto
  justify-content: center
  flex-direction: column
}
.main-content{
}
.scratchCard{
  width: 433px!important
  height: 174px!important
}
.scratchCard canvas{
  left: 0
  bottom: 0
  width: 433px!important
  height: 174px!important
}
.scratchCard .result{
  display: flex
  justify-content: center
  align-items: center
  font-size: 26px
  color: #f00
}
.scratchCard .pic{
  display: none
}
.account-container{
  position: relative
  margin-bottom: 30px
  margin-top: 50px
  display: flex
  flex-direction: column
  align-items: center
}
.title-bar{
  margin: 25px 0 0
  color: #fff
  font-weight: normal
  font-size: 28px
  margin-bottom: 25px
  letter-spacing: 4px
  font-weight: 600
}
.field__value{
  margin-bottom: 10px
}
.field__control{
  border: 0 none
  background: #39100b
  width: 302px
  height: 36px
  font-size: 18px
  padding: 0 5px
  margin-top: 10px
  color: #fff
}
.field__value-screen{
  border-radius: 40px
  display: flex
  flex-direction column
  justify-content center
}
.field__control-screen{
  border: 0 none
  width 272px
  height: 36px
  font-size: 18px
  padding: 0 10px 0 20px
  color: #000
}
.button--info-screen{
  background-image: linear-gradient(#f79605, #fef7c7 53%, #fcf97e)
  border: 0 none
  color: #bb2c1c
  width: 100%
  height: 36px
  font-size: 20px
  border-radius: 5px
  margin-top 15px
}
.button--info{
  background-image: linear-gradient(#f79605, #fef7c7 53%, #fcf97e)
  border: 0 none
  color: #bb2c1c
  width: 168px
  height: 30px
  font-size: 20px
  border-radius: 30px
  margin-top: 10px
}
.popup-search-block{
  display: flex
  flex-direction: column
  border-radius: 10px 10px 0 0
}
.block--title{
  background-image: linear-gradient(#dc722d, #cc2e1b)
  height: 48px
  display: flex
  align-items: center
  color: #fff
  font-size: 16px
  padding-left: 10px
}
.mcain-content{
  background: #94040c
  flex: 1
  padding: 20px 15px
}
.search-content{
  display: flex
  color: #fff
  font-size: 14px
  justify-content: center
}
.s-c-i{
  display: flex
   justify-content: center
   align-items: center
   margin-right: 8px
   .field__control{
    margin-top: 0
    font-size: 16px
  }
}
.s-c-b .button--info{
  margin-top: 0
  width: 120px
  font-size: 16px
}
.result-content {
  overflow: hidden
}
.rc-main{
  height: 350px
  overflow-y: scroll
  width: 767px
}
.rc-head, .rc-body{
  display: flex
  font-size: 14px
  color: #fff
  & li{
    flex: 1
    height: 42px
    display: flex
    align-items: center
    justify-content: center
  }
}
.rc-head li{
  margin-top: 10px
  border: 1px solid #a5050f
}
.mml-container{
  // margin-top 35px
}
.mmlc-content{
  display: flex
  flex-direction: column
  justify-content: center
}
.content-header
  display flex
  flex-direction column
  align-items flex-start
  justify-content space-between
  height 72px
  border-left 9px solid #e2ae30
  padding-left 8px
  overflow hidden
  h3
    margin 0
    padding 0
    font-size 38px
    color #fff
    line-height 1
  p
    margin 0
    padding 0
    color #fff
    font-size 24px
    line-height 1
.decorate-line
  background-color rgba(255, 255, 255, .15)
  width 100%
  height 7px
  margin 30px 0 0
  &.w560
    width 560px
.mmlc-citem
  min-height: 100px
  >>> h4
    margin 50px 0 0
    color: #e2ae30
    font-size: 28px
    text-align: left
    line-height: 32px
  >>> p
    color: #fff
    margin 0
    padding: 0
    font-size 18px
    text-align left
    margin-top 20px
.beaut-tbl
  flex 1
  margin-left 10px
  padding 0 15px
  >>> .mc-table
    border-right: 1px solid #d18f93
    border-bottom: 1px solid #d18f93
    width: 100%
    table-layout: fixed
    background-color #610b0f
    >>> p
      color: #fff
      padding: 0
      font-size 18px
  >>> & th, >>> & td
    border-left: 1px solid #d18f93
    border-top: 1px solid #d18f93
  >>> th
    height 110px
    color: #fffc04
  >>> td
    word-wrap break-word
    height: 48px
    color #fff
  >>> .even
    background-color #88272c
  >>> .odd
    background-color #610b0f
.copyright{
  margin-top: 65px
  background-color: #750b0b
  height: 92px
  p{
    color: #a9a9a9
    line-height: 92px
    font-size: 18px
    margin: 0
  }
}
.lucky-btn
  position: absolute;
  left: 220px
  top: 155px
  width: 760px
  height: 385px
  background-image: url('~@/assets/buttons-pct.png');
  background-repeat: no-repeat;
  background-size: auto 95%;
  background-position: center center;
  font-size: 0;
  display flex
  align-items flex-end
  z-index: 1040;
  .lcy-btn-inner
    background-image url('~@/assets/buttons-pcb.png')
    background-repeat no-repeat
    background-position center center
    background-size auto 100%
    width 100%
    height 175px
.animation
  .lcy-btn-inner
    background-image url('~@/assets/buttons.gif')
    background-size auto 130px
.van-overlay
  z-index: 9999
.popup-coupons-block
  background-color transparent
  background url('~@/assets/coupons.png') no-repeat
  .coupons-word
    margin: 280px auto 0
    padding: 0 80px
    font-size 18px
</style>