<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  methods: {
    isMobile() {
      return navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
   }
  },
  mounted () {
    if (!this.isMobile()) {
      this.$router.replace('/')
    } else {
      this.$router.replace('/mobile')
    }
  }
}
</script>

<style lang="stylus">
.time-down{
  color: #fff
  font-size: 32px
}
.time-clock{
  display: flex
  justify-content: center
  align-items: center
}
.time-down .title{
  margin-bottom: 12px
  letter-spacing: 2px
  font-weight: 600
}
.tc-item{
  display: flex
  flex-direction: column
  width: 72px
  height: 67px
  border: 3px solid #090101
  font-size: 18px
  border-radius: 7px
  justify-content: center
}
.tc-item + .tc-item{
  margin-left: 10px
}
.tc-item .num{
  display: flex
  flex: 1
  justify-content: center
  align-items: center
  background: #1f1d1e
  font-size: 24px
  font-weight: bold
}
.tc-item .txt{
  display: flex
  flex: 1
  justify-content: center
  align-items: center
  background: #434242
}
</style>
